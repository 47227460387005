import { keyframes, styled } from "styled-components";
import { Header } from "../../components/common/Header";
import bgImg from "../../assets/img/cleaneco_background.jpg";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { GetAllForLandingPage } from "../../api/client/GetAllForLandingPage";
import { GetAllCategories } from "../../api/client/GetAllCategories";

const PageWrap = styled.div``;
const BannerSection = styled.div`
  width: 100%;
  height: 150px;
  background: url(${bgImg}) center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  line-height: 150px;
  color: #fff;
  border-top: 2px solid #5bb331;

  h3 {
    font-size: 28px;
    font-weight: 300;
  }

  @media (max-width: 600px) {
    h3 {
      font-size: 20px;
    }
  }
`;

const ContentWrap = styled.div`
  width: 760px;
  margin: 0 auto;

  @media (max-width: 600px) {
    width: auto;
    padding: 20px;
    box-sizing: border-box;
  }
`;

const ani = keyframes`
from{
  opacity: 0;
  margin-top:40px;
}to{
  opacity: 1;
  margin-top:0px;
}
`;

const TextSection = styled.div`
  font-weight: 300;
  animation: ${ani} 1s;
  h4 {
    font-size: 24px;
    line-height: 50px;
    padding: 30px 0 10px 0;
    color: #5bb331;
  }

  & > p {
    color: #a7a7a7;
    position: relative;
    /* text-indent: 20px; */
    line-height: 30px;
    padding-left: 20px;

    &::before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-color: #5bb331;
      border-radius: 1px;
      position: absolute;
      left: 0;
      top: 7px;
    }
  }

  div {
    margin-top: 20px;
    background-color: #f2f2f2;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    padding: 30px;
    box-sizing: border-box;

    &.last_textbox {
      margin-top: 0px;
    }

    p {
      line-height: 30px;
      color: #797979;
    }
  }
`;
const BottomTextSection = styled.div`
  margin-top: 50px;
  background-color: #3c4a65;
  border-radius: 10px;
  padding: 30px;
  box-sizing: border-box;
  color: #fff;
  font-weight: 200;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 150px;
  margin-bottom: 50px;

  p:last-child {
    text-align: right;
    font-weight: 500;
  }
`;

export function ManagementPolicy() {
  const cookies = new Cookies();
  const current_cookies = cookies.get("user_info");

  const [categoryName, setCategoryName] = useState<any[]>([]);

  useEffect(() => {
    // getAllForLandingPage();
    getAllCategories();
  }, []);

  const params = {
    order_by: "category_id",
    order_type: "asc",
    set_order_by: "set_category_id",
    set_order_type: "asc",
  };

  const getAllCategories = () => {
    GetAllCategories(current_cookies, params).then((response) => {
      const prdList = response.data.data.categories_info.categories;
      const setPrdList = response.data.data.set_categories_info.set_categories;

      setCategoryName([...prdList, ...setPrdList]);
    });
  };

  const getAllForLandingPage = () => {
    GetAllForLandingPage(current_cookies).then((response) => {
      const prdList = response.data.data.single_category_prd_list;
      const setPrdList = response.data.data.set_category_set_name_list;

      setCategoryName([...prdList, ...setPrdList]);
    });
  };

  return (
    <PageWrap>
      <Header isWhiteLogo={false} categoryName={categoryName} />
      <BannerSection>
        <h3>경영 방침</h3>
      </BannerSection>
      <ContentWrap>
        <TextSection>
          <h4>경영방침</h4>
          <p>최고의 품질 정직한 제품 올바른 경영</p>
          <div>
            <p>
              고객이 만족하는 품질 추구를 위한 노력 <br /> 정직한 제품으로의
              고객 신뢰 <br /> 올바른 경영을 통한 노사 화합과 발전
            </p>
          </div>
        </TextSection>
        <TextSection>
          <h4>품질방침</h4>
          <p>
            주식회사 크린에코는 품질경영 시스템을 구축하여 품질방침과 목표를
            위해
            <br /> 충실히 이행한다. 품질방침과 목표는 KS Q ISO9001 : 2009
            품질경영 시스템 규격의
            <br />
            요구사항과 고객의 요구 사항을 만족시키고 당사의 경영이념을
            실현하는데 있다.
          </p>
          <div>
            <p>
              <b>고객의 만족을 위하여</b>
              <br />
              최고의 기술로 <br />
              최고의 제품으로 <br />
              고객과의 신뢰를 바탕으로
            </p>
          </div>
        </TextSection>
        <TextSection>
          <h4>환경방침</h4>
          <div className="last_textbox">
            <p>
              주식회사 크린에코는 환경경영 시스템의 합리적 구축 <br />
              환경법규 및 제반 환경관련 요구사항 준수 <br />
              환경개선노력과 오염방지노력을 지속적 전개
            </p>
          </div>
        </TextSection>
        <BottomTextSection>
          <p>
            <img src="" />
            상기 경영방침을 위해 관리 이사에게 경영 책임을 위임하되, 최종 책임은
            본인에게 있음을 분명히 한다.
          </p>
          <p>
            <b>주식회사 크린에코 &nbsp; 이현규</b>
          </p>
        </BottomTextSection>
      </ContentWrap>
    </PageWrap>
  );
}
